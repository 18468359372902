function App() {
  return (
    <div>
      <div className="jumbotron jumbotron-fluid" id="banner" style={{backgroundImage: 'url(img/banner.png)'}}>
          <div className="container text-center text-md-left">
              <header>
                  <div className="row justify-content-between">
                      <div className="col-2">
                          <a href="#" style={{textDecoration: 'none'}}><h1 style={{color: 'white'}}>4B</h1></a>
                      </div>
                      <div className="col-6 align-self-center text-right">
                          <a href="https://api.fourbcompany.com/terms/privacy" className="text-white lead">개인정보처리방침</a>
                          &nbsp;
                          &nbsp;
                          &nbsp;
                          <a href="https://api.fourbcompany.com/terms" className="text-white lead">이용약관</a>
                      </div>

                  </div>
              </header>
              <h1 data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="display-3 text-white font-weight-bold my-5" >
                  마음에 안들어 <br/>
                  그래서 만들어
              </h1>
              <p data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="lead text-white my-4">
                  우리는 마음에 들지 않는 세상을 조금씩 바꾸고 있습니다.<br/>
                  우리가 할 수 있는 것부터, 우리가 원하는 방식으로, <br/>
                  여러분 마음에 들도록.
              </p>
          </div>
      </div>
      
      <div className="jumbotron jumbotron-fluid feature" id="feature-first">
          <div className="container my-5">
              <div className="row justify-content-between text-center text-md-left">
                  <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" className="col-md-6">
                      <h2 className="font-weight-bold feature-title">레알밥도둑</h2>
                      <p className="my-4 feature-text">
                      모든 면에서 다른 급식.<br/>
                      여기 단 하나의 띵작.
                      </p>
                      <div className="download-link-container col-lg-6 col-md-12 col-sm-6">
                          <a href='https://play.google.com/store/apps/details?id=a4bcompany.com.realfood_android&hl=ko&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img className="badgegg"alt='다운로드하기 Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/ko_badge_web_generic.png'/></a>
                      </div>
                      <div className="download-link-container col-lg-6 col-md-12 col-sm-6">
                          <a href='https://itunes.apple.com/kr/app/%EB%A0%88%EC%95%8C%EB%B0%A5%EB%8F%84%EB%91%91-%EC%A0%84%EA%B5%AD-%EC%B4%88-%EC%A4%91-%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90-%EA%B8%89%EC%8B%9D%EC%8B%9D%EB%8B%A8%ED%91%9C-%ED%99%95%EC%9D%B8/id1300052598?mt=8'><img className="badgeap" src='img/appleb.svg'/></a>
                      </div>
                  </div>
                  <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" className="col-md-6 align-self-center">
                      <img src="img/realcapture.png" alt="Take a look inside" className="mx-auto d-block"/>
                  </div>
              </div>
          </div>
      </div>
      <div className="jumbotron jumbotron-fluid feature" id="feature-last">
          <div className="container">
              <div className="row justify-content-between text-center text-md-left">
                  <div data-aos="fade-left" data-aos-duration="1000" data-aos-once="true" className="col-md-6 flex-md-last">
                      <h2 className="font-weight-bold feature-title">무민이</h2>
                      <p className="my-4 feature-text">
                          "제일 가까운 무인민원발급기 위치 어디지?" <br/>
                          한번에 터치로 모든 발급기를 만나보세요.
                      </p>
                      <div className="download-link-container col-lg-6 col-md-12 col-sm-6">
                      <a href='https://play.google.com/store/apps/details?id=com.jutopia.lovelyfnt.moomin_android&hl=ko&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img  className="badgegg" alt='다운로드하기 Google Play' src='https://play.google.com/intl/en_us/badges/images/generic/ko_badge_web_generic.png'/></a>

                      </div>
                      <div className="download-link-container col-lg-6 col-md-12 col-sm-6">
                      <a href='https://itunes.apple.com/kr/app/%EB%AC%B4%EB%AF%BC%EC%9D%B4-%EC%A0%84%EA%B5%AD-%EB%AC%B4%EC%9D%B8%EB%AF%BC%EC%9B%90-%EB%B0%9C%EA%B8%89%EA%B8%B0-%EA%B2%80%EC%83%89-%EC%A7%80%EB%8F%84/id1231709581?mt=8'><img className="badgeap" src='img/appleb.svg'/></a>

                      </div>
                  </div>
                  <div data-aos="fade-right" data-aos-duration="1000" data-aos-once="true" className="col-md-6 align-self-center flex-md-first">
                      <img src="img/moocapture.png" alt="Safe and reliable" className="mx-auto d-block"/>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- three-block --> */}
      <div className="container my-5 py-2">
          <h2 className="text-center font-weight-bold my-5">Our Team</h2>
          <div className="row">
              <div data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center team">
                  <img src="img/pkh.jpg" alt="kyuhyeok park" className="mx-auto"/>
                  <h4>Co-founder<br/>개발자</h4>
                  <p>"끝날 때까지 끝난게 아니다."</p>
                      <a href="https://www.facebook.com/profile.php?id=100000157447092" className="d-inline-block text-center ml-2">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                      <a href="#" className="d-inline-block text-center ml-2">
                          <i className="fa fa-linkedin" aria-hidden="true"></i>
                      </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center team">
                  <img src="img/kms.jpg" alt="minsung kim" className="mx-auto"/>
                  <h4>Co-founder<br/>기획자</h4>
                  <p>"시작이 반이다."</p>
                  <a href="https://www.facebook.com/minsung.kim.7334504" className="d-inline-block text-center ml-2">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
              </div>
              <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once="true" className="col-md-4 text-center team">
                  <img src="img/who's next.png" alt="Smart Scan" className="mx-auto"/>
                  <h4>Who's Next?</h4>
              </div>
          </div>
      </div>
      <div className="jumbotron jumbotron-fluid" id="contact" style={{backgroundImage: 'url(img/banner.png)'}}>
          <div className="container my-5">
              <div className="row justify-content-between">
                  <div className="col-md-6 text-white">
                      <h2 className="font-weight-bold">Contact Us</h2>
                      <p className="my-4" >
                      저희에게 제안하실 사항이나, 문의사항이 있으시면<br/>
                      아래에 연락처를 통해 저희에게 보내주십시오.
                      </p>
                      <ul className="list-unstyled">
                          <li>Email : fourbcompany@gmail.com</li>
                      </ul>
                  </div>
                  <div className="col-md-6">
                      <form>
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label htmlFor="name">Your Name</label>
                                <input type="name" className="form-control" id="name"/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="Email">Your Email</label>
                                <input type="email" className="form-control" id="Email"/>
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea className="form-control" id="message" rows="3"></textarea>
                        </div>
                          <button type="submit" className="btn font-weight-bold atlas-cta atlas-cta-wide cta-green my-3">Submit</button>
                      </form>
                  </div>
              </div>
          </div>
      </div>

      {/* copyright */}
      <div className="jumbotron jumbotron-fluid" id="copyright">
            <div className="container">
                <div className="row justify-content-between">
                  <div className="col-md-6 text-white align-self-center text-center text-md-left my-2">
                        Copyright © 2023 4B Co.
                    </div>
                    <div className="col-md-6 align-self-center text-center text-md-right my-2" id="social-media">
                        <a href="https://www.facebook.com/realmealrobber" className="d-inline-block text-center ml-2">
                          <i className="fa fa-facebook" aria-hidden="true"></i>
                        </a>
                        <a href="https://youtu.be/onjY79NMieE" className="d-inline-block text-center ml-2">
                          <i className="fa fa-youtube" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default App;
